import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from '../https'
const Login = () => import('@/pages/Login.vue') // 登录
const WorkLibrary = () => import('@/pages/WorkLibrary.vue') // 作品库

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/work_library',
    name: 'WorkLibrary',
    component: WorkLibrary
  },
  {
    path: '/work_library_sz',
    name: 'WorkLibrarySz',
    component: WorkLibrary
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
