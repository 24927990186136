import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './https'
import store from './store'
import './plugins/element.js'
import qs from 'qs'
import Vant from 'vant'
import '@/assets/iconfont/iconfont.css'
import filters from './filters/index'
import clearTabIndex from './plugins/clearTabIndex.js' // 删除el-tooltip tabindex，解决tooltip包裹的内容点击后仍然出现的问题（点击跳出页面，再返回原页面tooltip仍然存留）
import infiniteScroll from "vue-infinite-scroll";
import '@/assets/css/common.scss';
import '@/assets/css/reset.scss';
import _ from 'lodash'

import 'vant/lib/index.css';
// 注意less文件一定放在css文件的下面，样式才可以生效
import 'vant/lib/index.less'

clearTabIndex.install(Vue)
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

Vue.use(infiniteScroll);

Vue.use(Vant)
Vue.prototype.$http = axios
Vue.prototype.qs = qs
Vue.prototype._ = _ //全部导入的挂载方式
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
