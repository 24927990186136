import axios from 'axios'
import store from './store/index'
 import router from './router/index'
import { Message } from 'element-ui'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  config => {
    // config.headers.token = 'e964fe86-e07f-4e60-b377-7eb0e3f3f174'

    // if (localStorage.getItem('open_id')) {
    //   config.headers.token = localStorage.getItem('open_id')
    // }
    // console.log(store.state)
    if(store.state.openId) {
      config.headers.token = store.state.openId
    }
        return config
    },
    error =>{
        return Promise.reject(error)
    }
)
axios.interceptors.response.use(
  response => {
    if (response.data.code === -999) {
      let route = ['/login']
      console.log(router.currentRoute.path)
      if (route.indexOf(router.currentRoute.path == -1)){
        if(router.currentRoute.path == '/work_library_sz') {
          router.replace({ path: '/login', query: { type: 'sz',redirect: router.currentRoute.fullPath } })
        }else{
          router.replace({ path: '/login', query: { redirect: router.currentRoute.fullPath } })
        }
      }
    } else {
      return response
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          Message.error('服务器异常，请稍后再试！');
      }
    }
    return Promise.reject(error)
  },

)
export default axios
