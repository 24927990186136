import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // userInfo: {
    //   username:'', // 用户名
    //   department:'', // 部门
    //   position: '' // 职位
    // },
    username:'',
    // 邮箱
    email:localStorage.getItem('email'),
    // 品牌
    company_name:localStorage.getItem('company_name'),
    openId: ''
  },
  mutations: {
    setOpenId(state,val) {
      state.openId = val
    }
    // setUserInfo(state, val) {
    //   state.userInfo = val
    // },
    // // 修改邮箱
    // setEmail(state, val) {
    //   state.email = localStorage.getItem('email') ? localStorage.getItem('email') : ''
    // },

  },
  actions: {
  },

  modules: {
  }
})
