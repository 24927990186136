<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<style lang="scss">
</style>
<script>

export default {
  name: 'app',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {},
}
</script>
